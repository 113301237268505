(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_layouts/section-odds-live/assets/javascripts/sockets.js') >= 0) return;  svs.modules.push('/components/banner_layouts/section-odds-live/assets/javascripts/sockets.js');
"use strict";

var _svs$oddset, _svs$oddset2;


const trinidad = svs.isServer ? require('trinidad-core').core : svs.core;
const getLogger = svs.isServer ? trinidad.logger : svs.core.log.getLogger;
const kambiPushApiUrl = (_svs$oddset = svs.oddset) === null || _svs$oddset === void 0 || (_svs$oddset = _svs$oddset.kambiOfferingApiUrl) === null || _svs$oddset === void 0 || (_svs$oddset = _svs$oddset.data) === null || _svs$oddset === void 0 ? void 0 : _svs$oddset.kambiPushApiUrl;
const kambiOfferingSocketChannelPrefix = (_svs$oddset2 = svs.oddset) === null || _svs$oddset2 === void 0 || (_svs$oddset2 = _svs$oddset2.kambiOfferingApiUrl) === null || _svs$oddset2 === void 0 || (_svs$oddset2 = _svs$oddset2.data) === null || _svs$oddset2 === void 0 ? void 0 : _svs$oddset2.kambiOfferingSocketChannelPrefix;

const SocketMessageType = {
  EventAdded: 4,
  MatchClockUpdated: 15,
  LiveEventOpened: 5,
  BetOfferAdded: 6,
  BetOfferOddsAdded: 22,
  BetOfferStatusUpdated: 8,
  EventRemoved: 18,
  MatchClockRemoved: 12,
  MatchOccurrence: 25,
  EventScoreUpdated: 16,
  EventStatsUpdated: 17,
  EventDescriptionUpdated: 20,
  BetOfferRemoved: 7,
  BetOfferOddsUpdated: 11,
  BetOfferOddsRemoved: 23,
  OutcomesStatusUpdated: 32,
  EventStateUpdated: 34,
  EventStartTimeUpdated: 35,
  toString: n => Object.keys(SocketMessageType).find(key => SocketMessageType[key] === n) || n
};
const normalizeSocketMessages = messageArr => messageArr.map(message => ({
  timestamp: message.t,
  type: message.mt,
  payload: message[Object.keys(message).find(key => key !== 't' && key !== 'mt')]
}));
const logger = getLogger('banner_layouts:section-odds-live');
const LANGUAGE = 'sv';
const MAXIMUM_ATTEMPTS = 5;
const setupSockets = function (eventId, onMessageCallback) {
  let onSocketError = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : () => {};
  if (!eventId) {
    throw new Error("Missing eventId. Not opening socket connection");
  }

  const socket = io(kambiPushApiUrl, {
    transports: ['websocket'],
    upgrade: false,
    autoConnect: false,
    path: '/socket.io'
  });
  socket.on('connect', () => {
    logger.debug('Socket connected');
  });
  socket.on('disconnect', reason => {
    logger.info("Socket disconnected: ".concat(reason));
  });
  socket.on('reconnect', attemptNumber => {
    logger.info("Socket reconnected after ".concat(attemptNumber, " attempts."));
  });
  socket.on('error', error => {
    const errorMsg = "Socket error: ".concat(error);
    logger.error(errorMsg);
    onSocketError(errorMsg);
  });
  socket.on('reconnect_attempt', attemptNumber => {
    logger.info("Socket reconnect attempt ".concat(attemptNumber));
    if (attemptNumber >= MAXIMUM_ATTEMPTS) {
      const errorMsg = "Closing socket after ".concat(attemptNumber, " reconnection attempts.");
      logger.error(errorMsg);
      onSocketError(errorMsg);
      socket.close();
    }
  });
  socket.on('message', incomingMessage => {
    const messages = JSON.parse(incomingMessage);
    onMessageCallback(normalizeSocketMessages(messages));
  });
  socket.open();
  const languageSpecificGeneralChannel = "".concat(kambiOfferingSocketChannelPrefix, ".").concat(LANGUAGE, ".ev.json");
  const nonLocalizedGeneralChannel = "".concat(kambiOfferingSocketChannelPrefix, ".ev.json");
  const languageSpecificChannel = "".concat(kambiOfferingSocketChannelPrefix, ".").concat(LANGUAGE, ".ev.").concat(eventId, ".json");
  const nonLocalizedChannel = "".concat(kambiOfferingSocketChannelPrefix, ".ev.").concat(eventId, ".json");
  socket.emit('subscribe', {
    topic: languageSpecificGeneralChannel
  });
  socket.emit('subscribe', {
    topic: nonLocalizedGeneralChannel
  });
  socket.emit('subscribe', {
    topic: languageSpecificChannel
  });
  socket.emit('subscribe', {
    topic: nonLocalizedChannel
  });
  return {
    unsubscribe: () => {
      socket.emit('unsubscribe', {
        topic: languageSpecificGeneralChannel
      });
      socket.emit('unsubscribe', {
        topic: nonLocalizedGeneralChannel
      });
      socket.emit('unsubscribe', {
        topic: languageSpecificChannel
      });
      socket.emit('unsubscribe', {
        topic: nonLocalizedChannel
      });
    }
  };
};
if (svs.isServer) {
  module.exports = {
    normalizeSocketMessages,
    setupSockets,
    SocketMessageType
  };
} else {
  setGlobal('svs.banner_layouts.section_odds_live.sockets', {
    normalizeSocketMessages,
    setupSockets,
    SocketMessageType
  });
}

 })(window);