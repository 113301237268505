(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_layouts/section-odds-live/views/index.js') >= 0) return;  svs.modules.push('/components/banner_layouts/section-odds-live/views/index.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.banner_layouts=_svs.banner_layouts||{};
_svs.banner_layouts.section_odds_live=_svs.banner_layouts.section_odds_live||{};
_svs.banner_layouts.section_odds_live.templates=_svs.banner_layouts.section_odds_live.templates||{};
svs.banner_layouts.section_odds_live.templates.index = Handlebars.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"eyebrowManualAdmin") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.program(4, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":17,"column":2},"end":{"line":26,"column":9}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"event") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.program(23, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":27,"column":2},"end":{"line":54,"column":9}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"body") : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":55,"column":2},"end":{"line":59,"column":9}}})) != null ? stack1 : "")
    + "  <div class=\"hero-actions\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"callToActions") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"if","hash":{},"fn":container.program(28, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":61,"column":4},"end":{"line":65,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"eventCta") : depth0),{"name":"if","hash":{},"fn":container.program(31, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":66,"column":4},"end":{"line":75,"column":11}}})) != null ? stack1 : "")
    + "  </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"hero-eyebrow js-hero-eyebrow\">\n      <span class=\"hero-eyebrow-text js-hero-eyebrow-text\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"eyebrowManualAdmin") || (depth0 != null ? lookupProperty(depth0,"eyebrowManualAdmin") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"eyebrowManualAdmin","hash":{},"data":data,"loc":{"start":{"line":19,"column":59},"end":{"line":19,"column":81}}}) : helper)))
    + "</span>\n    </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"hero-eyebrow js-hero-eyebrow\">\n      <span class=\"hero-eyebrow-group js-hero-eyebrow-group\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"eyebrow") : depth0)) != null ? lookupProperty(stack1,"group") : stack1), depth0))
    + "</span>\n      <span class=\"hero-eyebrow-time js-hero-eyebrow-time\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"eyebrow") : depth0)) != null ? lookupProperty(stack1,"time") : stack1), depth0))
    + "</span>\n    </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"hero-title hero-event-title"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"live") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":43},"end":{"line":28,"column":84}}})) != null ? stack1 : "")
    + "\">\n      <div class=\"hero-event-title-ellipsis hero-event-title-home js-hero-event-title-home\">\n        <span class=\"team-name\">"
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"event") : depth0)) != null ? lookupProperty(stack1,"homeName") : stack1), depth0))
    + "</span>\n      </div>\n      <div class=\"hero-delimiter js-hero-delimiter "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"live") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":51},"end":{"line":32,"column":91}}})) != null ? stack1 : "")
    + "\">\n        <div class=\"hero-score js-hero-score-home\" data-score=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"live") : depth0)) != null ? lookupProperty(stack1,"score") : stack1),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":33,"column":63},"end":{"line":33,"column":116}}})) != null ? stack1 : "")
    + "\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"live") : depth0)) != null ? lookupProperty(stack1,"score") : stack1),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":10},"end":{"line":34,"column":69}}})) != null ? stack1 : "")
    + "</div>\n        <div class=\"delimiter\">"
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"event") : depth0)) != null ? lookupProperty(stack1,"nameDelimiter") : stack1), depth0))
    + "</div>\n        <div class=\"hero-score js-hero-score-away\" data-score=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"live") : depth0)) != null ? lookupProperty(stack1,"score") : stack1),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":37,"column":63},"end":{"line":37,"column":116}}})) != null ? stack1 : "")
    + "\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"live") : depth0)) != null ? lookupProperty(stack1,"score") : stack1),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":10},"end":{"line":38,"column":69}}})) != null ? stack1 : "")
    + "</div>\n      </div>\n      <div class=\"hero-event-title-ellipsis hero-event-title-away js-hero-event-title-away\">\n        <span class=\"team-name\">"
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"event") : depth0)) != null ? lookupProperty(stack1,"awayName") : stack1), depth0))
    + "</span>\n      </div>\n    </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"live") : depth0)) != null ? lookupProperty(stack1,"time") : stack1),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":45,"column":4},"end":{"line":49,"column":11}}})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    return " hero-event-title-live";
},"9":function(container,depth0,helpers,partials,data) {
    return " hero-delimiter-score";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"live") : depth0)) != null ? lookupProperty(stack1,"score") : stack1)) != null ? lookupProperty(stack1,"home") : stack1), depth0));
},"13":function(container,depth0,helpers,partials,data) {
    return "0";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"live") : depth0)) != null ? lookupProperty(stack1,"score") : stack1)) != null ? lookupProperty(stack1,"home") : stack1), depth0))
    + "</span>";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"live") : depth0)) != null ? lookupProperty(stack1,"score") : stack1)) != null ? lookupProperty(stack1,"away") : stack1), depth0));
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"live") : depth0)) != null ? lookupProperty(stack1,"score") : stack1)) != null ? lookupProperty(stack1,"away") : stack1), depth0))
    + "</span>";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"hero-time\">\n        <span>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"live") : depth0)) != null ? lookupProperty(stack1,"time") : stack1), depth0))
    + "</span>\n      </div>\n";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"noEvent") : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":2},"end":{"line":54,"column":2}}})) != null ? stack1 : "");
},"24":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"hero-title hero-event-title\">\n      <span>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"noEvent") || (depth0 != null ? lookupProperty(depth0,"noEvent") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"noEvent","hash":{},"data":data,"loc":{"start":{"line":52,"column":12},"end":{"line":52,"column":23}}}) : helper)))
    + "</span>\n    </div>\n  ";
},"26":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"hero-body\">\n      <p>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"body") || (depth0 != null ? lookupProperty(depth0,"body") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"body","hash":{},"data":data,"loc":{"start":{"line":57,"column":9},"end":{"line":57,"column":17}}}) : helper)))
    + "</p>\n    </div>\n";
},"28":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"callToActions") : depth0),{"name":"each","hash":{},"fn":container.program(29, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":62,"column":6},"end":{"line":64,"column":15}}})) != null ? stack1 : "");
},"29":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + container.escapeExpression((lookupProperty(helpers,"dynamicActionTemplate")||(depth0 && lookupProperty(depth0,"dynamicActionTemplate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"actionType") : depth0),(depth0 != null ? lookupProperty(depth0,"actionConfig") : depth0),(depths[1] != null ? lookupProperty(depths[1],"trackingString") : depths[1]),{"name":"dynamicActionTemplate","hash":{},"data":data,"loc":{"start":{"line":63,"column":8},"end":{"line":63,"column":75}}}))
    + "\n";
},"31":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"button",{"name":"ui","hash":{"title":((stack1 = (depth0 != null ? lookupProperty(depth0,"eventCta") : depth0)) != null ? lookupProperty(stack1,"linkTitle") : stack1),"target":"internal","href":((stack1 = (depth0 != null ? lookupProperty(depth0,"eventCta") : depth0)) != null ? lookupProperty(stack1,"linkUrl") : stack1),"data-click-tracking":(depth0 != null ? lookupProperty(depth0,"trackingString") : depth0)},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":67,"column":6},"end":{"line":74,"column":13}}})) != null ? stack1 : "");
},"32":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"eventCta") : depth0)) != null ? lookupProperty(stack1,"linkTitle") : stack1), depth0)) != null ? stack1 : "")
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"components-lb_ui-hero-hero"),depth0,{"name":"components-lb_ui-hero-hero","hash":{"width":(depth0 != null ? lookupProperty(depth0,"width") : depth0),"trackingString":(depth0 != null ? lookupProperty(depth0,"trackingString") : depth0),"tab":(depth0 != null ? lookupProperty(depth0,"tab") : depth0),"slim":(depth0 != null ? lookupProperty(depth0,"slim") : depth0),"order":(depth0 != null ? lookupProperty(depth0,"order") : depth0),"loading":(depth0 != null ? lookupProperty(depth0,"loading") : depth0),"invertArrow":(depth0 != null ? lookupProperty(depth0,"invertArrow") : depth0),"image":(depth0 != null ? lookupProperty(depth0,"image") : depth0),"height":(depth0 != null ? lookupProperty(depth0,"height") : depth0),"dataset":(depth0 != null ? lookupProperty(depth0,"dataset") : depth0),"cube":(depth0 != null ? lookupProperty(depth0,"cube") : depth0),"crm":(depth0 != null ? lookupProperty(depth0,"crm") : depth0),"class":(depth0 != null ? lookupProperty(depth0,"classes") : depth0),"branding":(depth0 != null ? lookupProperty(depth0,"branding") : depth0)},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"usePartial":true,"useData":true,"useDepths":true});
Handlebars.partials['components-banner_layouts-section_odds_live-index'] = svs.banner_layouts.section_odds_live.templates.index;
})(svs, Handlebars);


 })(window);